import styled from "styled-components";

export const StyledButton = styled("button")<{ color?: string }>`
  background: ${(p) => p.color || "#211F54"};
  color: ${(p) => (p.color ? "#211F54" : "#fff")};
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #edf3f5;
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:disabled {
    cursor: unset;
    opacity: 0.6;
  }

  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      border: 1px solid #6db5e7;
      background-color: #6db5e7;
    }
  }
`;
